<!--
 * @Descripttion:
 * @version:
 * @Author: guomengxia
 * @Date: 2024-02-01 10:05:02
 * @LastEditors: wenboliu
 * @LastEditTime: 2024-04-30 14:50:56
-->
<template>
  <el-dialog
    :visible.sync="addPaperShow"
    :before-close="cancel"
    :close-on-click-modal="false"
    title="创建试卷"
    width="400px">
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="80px"
      @keyup.enter.native="_submitAddPaper()">
      <el-form-item label="所属分组" prop="group">
        <el-select class="main-select-tree" ref="selectTree" v-model="dataForm.group" style="width:100%;">
          <el-option
            v-for="item in groupLists"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            style="display: none"/>
              <el-tree
                class="main-select-el-tree"
                ref="selecteltree"
                :data="treeData"
                node-key="id"
                highlight-current
                :props="defaultProps"
                @node-click="_handleNodeClick"
                default-expand-all/>
        </el-select>
      </el-form-item>
      <el-form-item label="试卷名称" prop="name">
        <el-input v-model="dataForm.name" placeholder="请输入试卷名称" style="width:275px;" clearable></el-input>
      </el-form-item>
      <el-form-item label="试卷描述">
        <el-input v-model="dataForm.description" type="textarea" placeholder="请输入" style="width:275px;" rows="1"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="_submitAddPaper()">确认</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getManageDeptListApi } from '@/api/api'
import { getPaperAddApi } from '@/api/examApi'
export default {
  props: {
    addPaperShow: {
      type: Boolean,
      default: false
    },
    treeData: {
      type: Array,
      default: () => []
    },
    defaultProps: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      visibleDeptList: [], // 可见部门列表
      groupLists: [{}],
      dataForm: {
        group: '', // 分组名称
        parentId: '', // 分组父id
        testPaperGroupId: 0, // 分组id
        name: '', // 试卷名称
        description: '' // 试卷描述
      },
      dataRule: {
        group: [
          { required: true, message: '请选择所属分组', trigger: ['change', 'blur'] }
        ],
        name: [
          { required: true, message: '请输入试卷名称', trigger: ['change', 'blur'] }
        ]
      }
    }
  },
  created () {
    this._getManageDeptList()// 部门下拉列表
  },
  methods: {
    // 取消
    cancel () {
      this.$parent.addPaperShow = false
    },
    // 点击节点的响应
    _handleNodeClick (node) {
      this.dataForm.group = node.groupName
      this.dataForm.parentId = node.parentId
      this.dataForm.testPaperGroupId = node.id
      this.$refs.selectTree.blur()
    },
    // 部门下拉列表
    _getManageDeptList () {
      getManageDeptListApi().then((res) => {
        if (res.code === 200) {
          this.visibleDeptList = res.data
        }
      })
    },
    _submitAddPaper () { // 创建试卷
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          console.log(this.dataForm, 'this.dataForm')
          // return
          getPaperAddApi({ ...this.dataForm }).then((res) => {
            if (res.code === 200) {
              this.$message.success('创建成功！')
              this.$emit('submitAddPaper', res.data)
            }
          })
        }
      })
    },
    // 选择部门
    _selectDept (item) {
      this.dataForm.useDeptId = item.id
      this.dataForm.useDeptName = item.name
    }
  }
}
</script>
<style lang="scss" scoped>
.radius {
  width: 93%;
  background: #f6f7f9;
  border-radius: 4px 4px 4px 4px;
  padding: 12px;
  margin-top: 8px;
  .radiu-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333333;
    font-size: 14px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}
.tag-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.button-new-tag {
  padding-top: 0;
  padding-bottom: 0;
  width: 78px;
  height: 28px;
  text-align: center;
  padding: 8px 9px;
  margin-bottom: 10px;
}
.tag-item {
  margin-right: 10px;
  width: 78px;
  height: 28px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 10px;
}
.add-group {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  .add-group-btn {
    width: 102px;

    border: 1px solid #0089ff;
    font-size: 14px;
    color: #0089ff;
  }
}
.add-group-name {
  margin-bottom: 10px;
  width: 298px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 14px;
    height: 14px;
    margin-left: 8px;
    flex: 1;
  }
}
/deep/.el-dialog__body {
  max-height: 420px;
  overflow: auto;
}
</style>
